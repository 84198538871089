import { useState } from 'react'

import { ImageCarousel } from './image-carousel/ImageCoursel'

import { MapMarker } from '@/components/react/atoms/MapMarker'
import type { Destination } from '@/types/types'
import australia from '@/assets/images/australia@2x.png'

const locations: Destination[] = [
  {
    text: 'Magnetic Island',
    uri: '/australia/nomads-magnetic-island/',
    top: 20,
    right: -60,
  },
  {
    text: 'Airlie Beach',
    uri: '/australia/nomads-airlie-beach/',
    top: 25,
    right: -67,
  },
  {
    text: 'Noosa',
    uri: '/australia/nomads-noosa/',
    top: 37.5,
    right: -89,
  },
  // {
  //   text: 'Brisbane',
  //   uri: '/australia/nomads-brisbane/',
  //   top: 44,
  //   right: -95,
  // },
  {
    text: 'Byron Bay',
    uri: '/australia/arts-factory-lodge/',
    top: 49,
    right: -95,
  },
  {
    text: 'Sydney',
    uri: '/australia/nomads-sydney/',
    top: 63,
    right: -84,
  },
  {
    text: 'St Kilda, Melbourne',
    uri: '/australia/nomads-st-kilda-melbourne/',
    top: 78,
    right: -44,
    lineLength: 32,
  },
]
export const AustraliaSectionHome = ({ hostels }) => {
  const [currentImageUri, setCurrentImageUri] = useState<string>(null)

  const getImageOnHover = (uri) => {
    setCurrentImageUri(uri)
  }
  return (
    <div className="mt-20 md:mt-[250px] relative flex items-center">
      <div className=" max-w-[60%] md:max-w-[30%] lg:max-w-[30%]  md:absolute ">
        <div className="relative w-fit">
          <img
            src={australia.src}
            alt="Australia"
            width={australia.width}
            height={australia.height}
            loading="lazy"
          />
          {locations.map((location) => (
            <MapMarker
              text={location.text}
              uri={location.uri}
              top={location.top}
              right={location.right}
              lineLength={location.lineLength}
              getImageOnHover={getImageOnHover}
              key={location.text}
            />
          ))}
          <h3 className="absolute top-0 right-[-26%] md:top-[5%] md:right-[-25%]  text-blue text-4xl md:text-3xl lg:text-5xl 2xl:text-6xl 3xl:text-[52px] font-black">
            Australia
          </h3>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-12 md:gap-8 items-center container  ">
        <div className="md:order-1 md:col-start-8 md:col-end-13 relative   md:max-w-none justify-self-end md:justify-self-none h-full w-full md:-mt-[15%] -mt-52 hidden md:block ">
          <ImageCarousel
            scrollToUri={currentImageUri}
            borderColor="border-blue"
            items={hostels.map((item) => ({
              id: item.uri,
              title: 'Australia Hostels',
              subtitle: item.title,
              imageUrl: item.featuredImage.node.sourceUrl,
              srcSet: item.featuredImage.node.srcSet,
              altText: item.featuredImage.node.altText,
              uri: item.uri,
            }))}
          />
        </div>
      </div>
    </div>
  )
}
